<template>
    <div class="handle-business" :style="{height:height+'px'}">

       <div class="notice-bar">请选择您需要办理的业务</div>
      <div class="item" v-for="item in newList" :key="item.id" @click="toUrl(item)">
        <div style="display:flex; justify-content: left;align-items: center;font-size:16px;padding-left:20%" >
          <div class="itemTitle" :style="{color:'#333333'}"><span class="imgBox"><img style="width:100%" :src="item.icon" alt=""></span> {{item.title}}</div>
          <!-- <div class="mark" :style="{borderColor:id==item.id?'#4180F9':'#B4B4B4'}"><p class="circular" :style="{backgroundColor:id==item.id?'#4180F9':'#B4B4B4'}"/></div> -->
        </div>
      </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
           list:[
               {id:1,title:'返程人员登记',url:`/pages/control/returnPlus`, key: 'vaccReturn',icon:require('@/assets/img/hb1.png')},
               {id:4,title:'新冠抗原检测',url:'/antigen-home', key: 'antigenTest',icon:require('@/assets/img/hb3.png')},
               {id:5,title:'核酸采样信息登记',url:'/nucleic-register', key: 'nucleicRegister',icon:require('@/assets/img/hb4.png')},
               {id:3,title:'小区出入通行证领取',url:'/receive-code', key: '1',icon:require('@/assets/img/hb2.png')},
               {id:2,title:'从业人员核酸结果申报',url:'/employees', key: 'employees',icon:require('@/assets/img/hb5.png')},
           ],
           id:1,
           url:`/pages/control/returnPlus`,
           newList: [],
           height:document.documentElement.clientHeight
        }
    },
    created(){
     this.getList()
    //  this.height=this.document.documentElement.clientHeight
     console.log(document.documentElement.clientHeight);
    },
    methods:{
        getList(){
         this.$http({
          url: this.$http.adornUrl('/wxapp/application/getMyAppList'),
          method:'post',
          params:this.$http.adornParams({
              orgId: Number(this.$store.state.returnData.orgId)
          })
        }).then(({data})=>{
           if (data && data.code === 0) {
             data.list.list.map(item => {
               this.list.map(val => {
                 if (item.appCode == val.key) {
                   this.id = val.id
                   this.newList.unshift(val)
                 }
               })
             })
             // if (this.$store.state.returnData.rt) {
             //   data.list.list.map(item => {
             //     if (item.appCode == 'vaccReturn') {
             //       this.list = newList.slice(0, 1)
             //     }
             //   })
             // }
           }
        })
        },
        toUrl (item) {
          if (item.id==1) {
            if (this.$agentType == 1) {
              wx.miniProgram.navigateTo({
                url: item.url
              })
            } else {
              this.$router.push('/return')
            }
          }
          else if(item.id==2||item.id==4||item.id==5){
            this.$router.push('/appSSO?appId=' + item.key +  '&codeS=1&url='+item.url.split('/')[1])
            // this.$router.push('/appSSO?appId=antigenTest&codeS=1&url=nucleic-register')
          }
          else {
            this.$router.push(item.url)
          }

        }
    }

}
</script>

<style scoped>
   .handle-business{
       width: 100%;
       background-color: transparent !important;
       background: url('~@/assets/img/hbBgc.png');
       z-index: -10 !important;
   }
   .notice-bar{
     text-align: center;
     height: 68px;
     line-height: 68px;
     color: #FFFFFF;
     font-size: 24px;
     background-color: #689BFB;
   }
  .item{
      height: 152px;
      line-height: 152px;
      padding:0 40px;
      border: 1px solid #D8D8D8;
      border-radius: 20px;
      background-color: #FFFFFF;
      margin: 24px 30px;
      box-shadow:0 2px 12px 0 rgb(0 0 0 / 10%);
      z-index: 1000 !important;
  }
  .imgBox{
    display: inline-block;
    width: 44px;
    height: 44px;
  }
  .imgBox >>> img{
    width: 100%;
    margin-bottom: 6px;
  }
  .itemTitle{
      text-align: left;
      /* width: 55%; */
      font-size: 32px;
      font-weight: 700;
  }
   .mark{
       margin-top:3px ;
       width: 32px;
       height: 32px;
       display: flex;
       align-items: center;
       justify-content: center;
       border: 1px solid;
       overflow: hidden;
       border-color: #B4B4B4;
       border-radius: 50%;
       margin-right: 10px;
   }
   .circular{
        width: 14px;
        height: 14px;
        overflow: hidden;
        border-radius: 50%;
        background-color: #B4B4B4;
    }
    .van-notice-bar >>>.van-notice-bar__wrap{
       justify-content: center;
   }
</style>
